import { Loading } from '@actinc/dls/components/Loading';
import { useRouter } from 'next/router';
import React from 'react';

import { REDIRECT_TO_HOME_ROUTES, ROUTES, ROUTES_REGEX } from '~/constants/ROUTES';
import { asError } from '~/helpers/errors';
import Logger from '~/helpers/logger';
import { isPathEquivalent, isPathValidRoute, trimAnchor } from '~/helpers/path';
import { sentryRoutingError } from '~/helpers/SentryErrors';
import useObjectState from '~/hooks/useObjectState';

import NotFound404View from './NotFound404View';
import { StyledContainer } from './styles';

interface IProps {
  disableRedirect?: boolean;
}

const INITIAL_STATE = {
  loading: true,
  logged404: true,
  navigating: false,
  shouldNavigate: false,
};

const logger = new Logger('NotFound404');

const NotFound404: React.FC<IProps> = ({ disableRedirect = false }: IProps): React.ReactElement<unknown> => {
  const router = useRouter();

  const { loading, logged404, navigating, setLoading, setLogged404, setNavigating, setShouldNavigate, shouldNavigate } = useObjectState(INITIAL_STATE);

  logger.debug({ disableRedirect, loading, logged404, router, ROUTES_REGEX, shouldNavigate });

  React.useEffect((): void => {
    if (!disableRedirect) {
      if (isPathEquivalent(ROUTES.INDEX, router.asPath)) {
        setShouldNavigate(true);
      } else {
        setShouldNavigate(isPathValidRoute(router.asPath));
      }
      setLoading(false);
    }
  }, [disableRedirect, router.asPath, setLoading, setShouldNavigate]);

  React.useEffect((): void => {
    if (shouldNavigate && !navigating) {
      logger.debug('Valid route, calling router.replace', { 'router.asPath': router.asPath });
      router
        .replace(trimAnchor(router.asPath))
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(result => {
          logger.debug(`router.replace returned ${result}`);
          setNavigating(true);
          return result;
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((unkErr: unknown) => {
          sentryRoutingError({ error: unkErr, message: 'Error while replacing 404 route', to: trimAnchor(router.asPath) });
          const error = asError(unkErr);
          logger.error(error, 'Error while replacing 404 route');
        });
    } else if (!loading && !logged404) {
      logger.error(`${router.asPath} requested: 404`);
      setLogged404(true);
    }
  }, [loading, logged404, navigating, router, setLogged404, setNavigating, shouldNavigate]);

  React.useEffect(() => {
    if (!loading && !shouldNavigate && REDIRECT_TO_HOME_ROUTES.includes(router.asPath)) {
      setTimeout(async () => {
        await router.replace(ROUTES.INDEX);
      });
    }
  }, [loading, router, shouldNavigate]);

  return (
    <StyledContainer>
      {(loading || shouldNavigate) && <Loading />}
      {/* We need to add this check here to guarantee that the 404 content will not flicker on the sreen while router.push is being resolved */}
      {!loading && !shouldNavigate && !REDIRECT_TO_HOME_ROUTES.includes(router.asPath) && <NotFound404View returnUrl={ROUTES.INDEX} />}
    </StyledContainer>
  );
};

export default NotFound404;
