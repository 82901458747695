import EmptyState from '@actinc/dls/components/EmptyState';
import Button from '@mui/material/Button';
import React from 'react';

import Link from '~/components/Link';
import TAG_IDS from '~/constants/tagIds';
import getIcon from '~/helpers/getIcon';

import { StyledPaper } from './styles';

const IconAlert = getIcon('general.alert');

interface IProps {
  returnUrl?: string;
}

const NotFound404View: React.FC<IProps> = ({ returnUrl }) => (
  <StyledPaper>
    <EmptyState
      description="The page you were looking for does not exist, or it may be private."
      descriptionTypographyProps={{
        color: 'textPrimary',
      }}
      Icon={IconAlert as React.FC<unknown>}
      title="Page Not Found"
      titleTypographyProps={{
        component: 'h3',
      }}
    />
    {!!returnUrl && (
      <Link href={returnUrl} underline="none">
        <Button color="primary" id={TAG_IDS[404].BUTTON_RETURN_HOME} variant="contained">
          Return Home
        </Button>
      </Link>
    )}
  </StyledPaper>
);

export default NotFound404View;
