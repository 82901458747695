import { upperFirst } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';

import { typedKeys } from '~/helpers/type';

export type ReactSetStateCollection<T> = {
  [P in keyof T as `set${Capitalize<P & string>}`]: Dispatch<SetStateAction<T[P]>>;
};

export type ReactStateCollection<T> = T & ReactSetStateCollection<T>;

export type ReactStateResetCollection<T> = ReactStateCollection<T> & {
  resetObjectState: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useObjectState = <T extends Record<string, unknown>>(objState: T): ReactStateResetCollection<T> => {
  const result = typedKeys(objState).reduce((prev, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(objState[key]);
    return { ...prev, [key]: value, [`set${upperFirst(key as string)}`]: setValue };
  }, {} as ReactStateResetCollection<T>);
  result.resetObjectState = (): void => {
    typedKeys(objState).forEach((key): void => {
      (result[`set${upperFirst(key as string)}`] as Dispatch<SetStateAction<unknown>>)(objState[key]);
    });
  };

  return result;
};

export default useObjectState;
