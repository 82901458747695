/* eslint-disable filenames/match-regex, filenames/match-exported */

import { NextPage } from 'next';
import React from 'react';

import NotFound404 from '~/components/NotFound404';

const Page404: NextPage = (): React.ReactElement<unknown> => <NotFound404 />;

export default Page404;
